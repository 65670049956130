<template>
  <v-row no-gutters> 
    <v-col cols="12" class="px-0">
      <v-row class="px-1 py-3"> 
        <v-col cols="12" sm="6" md="6" class="d-flex align-center">
          <!-- <span class="text-h6 font-weight-bold mr-2 primary--text">
            Listado de Pedidos 
          </span>   -->
          <consultar-rango-fecha @consultar="solicitarPedidos" />
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
          <search-expand 
            v-model="search" 
            :load="loading"
            placeholder="Buscar Pedido"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6" 
          class="d-flex align-center justify-end"
          :class="{'justify-end': $vuetify.breakpoint.smAndUp, 'justify-center mb-3': $vuetify.breakpoint.xsOnly}" 
        >
          <search-expand 
            v-model="search" 
            :load="loading"
            placeholder="Buscar Pedido"
            :expand="!$vuetify.breakpoint.xsOnly"
            v-if="!$vuetify.breakpoint.xsOnly"
          />
         <v-btn
            color="icono"
            class="mx-2"
            outlined
            small
            @click="updateData"
          >               
            <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-update</v-icon>             
            <span v-if="!$vuetify.breakpoint.smAndDown">Actualizar</span>      
          </v-btn>
          <v-btn
            color="primary"
            class="mx-2"
            small
            @click="showFilters = !showFilters"
          >               
            <v-icon size="22" :left="!$vuetify.breakpoint.smAndDown">mdi-sort-variant</v-icon>             
             <span v-if="!$vuetify.breakpoint.smAndDown">Filtros</span>               
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-if="showFilters">
          <v-row class="px-5 py-3">
            <v-col cols="12" class="pb-0">
              <span class="caption text-uppercase d-block font-weight-bold">
                Tipos de Precios
              </span>            
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 1"
                color="icono"
                value="1"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 2"
                color="icono"
                value="2"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 3"
                color="icono"
                value="3"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 4"
                color="icono"
                value="4"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-checkbox
              v-model="selected_price"
                label="Lista Precio 5"
                color="icono"
                value="5"
                hide-details
                class="mt-0 pt-0"
              ></v-checkbox>
            </v-col>
            <!-- <v-col cols="12" class="pb-0">
              <span class="caption text-uppercase d-block font-weight-bold">
                Rango de Fechas {{rango_inicio}} {{rango_final}}
              </span>            
            </v-col>
            <v-col cols="12" 
              class="d-flex flex-row"
              :class="{'flex-row align-end': $vuetify.breakpoint.smAndUp, 'flex-column': $vuetify.breakpoint.xsOnly}"
              >
              <div>
                <label-form text="Inicio" required/>
                <input-fecha 
                  v-model="rango_inicio" 
                  color="blue-grey lighten-5"
                  />
              </div>
              <span class="blue-grey--text font-weight-medium mx-4 mt-3 caption">-</span>
              <div>
                <label-form text="Final" required/>
                <input-fecha 
                  v-model="rango_final" 
                  color="deep-orange lighten-5"
                  :disabled="!rango_inicio" 
                  :min="rango_inicio"                
                /> 
              </div>
              <div>
                <v-btn
                  color="blue-grey lighten-5"
                  class="mx-2"
                  depressed
                  @click="resetDate"
                  :block="$vuetify.breakpoint.xsOnly"
                >                          
                  Limpiar        
                </v-btn>
              </div>
            </v-col> -->
          </v-row>
        </div>
      </v-expand-transition>
    </v-col>  
    <v-col cols="12">
      <v-overlay 
        :value="loadDetail"
        color="white" 
        absolute
        opacity=".8"
      >
        <div class="d-flex flex-column justify-center">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"  
            class="mx-6"        
          ></v-progress-circular> <br>
          <span class="primary--text py-5">Obteniendo Pedido</span>
        </div>
      </v-overlay>
      <v-divider />
      <v-data-iterator
        :items="dataPedidos"
        :items-per-page="10"
        :search="search"
        :loading="loading"
        v-if="$vuetify.breakpoint.mobile"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
      >
        <template v-slot:default="{ items }">
          <template v-for="(pedido,i) in items">
            <mobile-pedidos :pedido="pedido" :key="'mobilep-'+i">
              <template #action>
                <v-btn 
                  depressed
                  color="secondary"
                  outlined
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  @click="showPedido(pedido)"
                >
                <v-icon left size="20">mdi-text-box-check-outline</v-icon>
                Detalles
                </v-btn>
              </template>
            </mobile-pedidos>
          </template>
        </template>
        <template #loading>
          <v-skeleton-loader
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
      </v-data-iterator>
       <v-data-table
        :headers="headers"
        :items="dataPedidos"
        :search="search"
        :loading="loading"
        :page.sync="pageData"
        must-sort
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        v-else
      >
        <template v-slot:item.Numero="{ item }">
           <span class="primary--text font-weight-bold" v-text="item.Numero" />
        </template>
        <template v-slot:item.cli_des="{ item }">
           <span v-text="item.cli_des" />
           <span class="font-weight-bold" v-text="' ('+item.Cliente+')'" />
        </template>
        <template v-slot:item.Fecha="{ item }">
         <span class="blue-grey--text"> {{item.Fecha | smartDate}} </span>
        </template>
        <template v-slot:item.Observaciones="{ item }">
          <span 
            class="d-inline-block text-truncate"
            style="max-width: 250px;" 
            v-text="item.Observaciones" 
          />
        </template>
        <template v-slot:item.TipoPrecio="{ item }">
          Nro. {{item.TipoPrecio | precio_venta}}
        </template>
        <template v-slot:item.Procesado="{ item }">
          <div class="d-flex justify-center">
            <v-chip 
              small 
              :color="item.Procesado ==='N' ? 'red' : 'success'" 
              dark v-text="item.Procesado"
            ></v-chip>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="actions--style text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn           
                  depressed
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  v-bind="attrs"
                  v-on="on"
                  @click="showPedido(item)"
                >
                <v-icon size="20" color="primary">mdi-file-move-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver Detalles</span>
            </v-tooltip>          
          </div>
        </template>
        <template #loading>
          <v-skeleton-loader
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
        <template v-slot:no-data>
          <div class="text-center pa-4">
            <span class="blue-grey--text font-italic">No se encontraron Pedidos Registrados</span>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
export default {
  name: 'PedidosCliente',
  components:{
    MobilePedidos: () => import(
      /* webpackChunkName: "modal-account" */
      './MobilePedidos.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    // InputFecha: () => import(
    //   /* webpackChunkName: "input-fecha" */
    //   '@/views/Reportes/Components/InputFecha.vue'
    // ),

  },
  props:{
    headers: {
      type: Array,
      default: () => ([]),
    },
    pedidosv: {
      type: Array,
      default: () => ([]),
    },
    loading:{
      type: Boolean,
      default: false
    },
    loadDetail: Boolean
  },
  data: () => ({
    search: '',
    selected_price: [],
    rango_inicio: '',
    rango_final: '',
    showFilters: false,
    pageData: 1
  }),
  watch: {
    pageData(val){
      this.pageData = 0;
    }
  },
  computed: {
    dataPedidos () {
      return this.pedidosv.filter(item => {
        let [moneda, precio] = item.TipoPrecio.toString().split('');
        return this.selected_price.length > 0 ? this.selected_price.includes(precio) : item
      })
      // .filter(item => {
      //   return this.rango_inicio != '' && this.rango_final != '' ? moment(item.Fecha).isBetween(this.rango_inicio, this.rango_final, undefined, '[]') : item ;
      // })
    },
  },
  methods:{
    showPedido(item){
      this.$emit('showDetails', item);
    },
    updateData(){
      this.$emit('update');
    },
    resetDate () {
      this.rango_inicio = ''
      this.rango_final = ''
    },
    solicitarPedidos(fechas){
      this.$emit('updateData', fechas);
    }
  },
  filters:{
    precio_venta(price){
      if(price != null){
        const numbreLista = price.toString().split('');
        return numbreLista[1];
      }

      return price;
    }
  }
}
</script>